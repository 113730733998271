import { render, staticRenderFns } from "./RegistrationForm.vue?vue&type=template&id=05e0bec6&scoped=true&"
import script from "./RegistrationForm.vue?vue&type=script&lang=js&"
export * from "./RegistrationForm.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationForm.vue?vue&type=style&index=0&id=05e0bec6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e0bec6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05e0bec6')) {
      api.createRecord('05e0bec6', component.options)
    } else {
      api.reload('05e0bec6', component.options)
    }
    module.hot.accept("./RegistrationForm.vue?vue&type=template&id=05e0bec6&scoped=true&", function () {
      api.rerender('05e0bec6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/login/components/RegistrationForm.vue"
export default component.exports